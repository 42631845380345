<template>
  <v-container
    id="Homepage"
    fluid
    tag="section"
  >
    <v-row
      justify="center"
      style="pa-5"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <base-material-card
          title="Research"
          height="100%"
          color="#454545"
        >
          <!-- Fills in the information below the avatar icon. pa-0 removes the padding. -->
          <v-card-text
            class="text-left pa-0"
          >
            <font
              size="4"
            >
              Modal analysis techniques are used to both characterize oscillations and assess the small signal stability of a power system.
              With the advent of large scale synthetic power systems, this research looks to understand and optimize new modal analysis techniques
              that were specifically designed to be able to handle large scale power systems. The primary method I am looking at is the
              Iterative Matrix Pencil method, which has undergone testing against other existing techniques, like Dynamic Mode Decomposition.
              I am primarily interested in using the Iterative Matrix Pencil method to analyze and understand the nature of system modes, and
              potentially use this knowledge to develop appropriate techniques to damp out oscillations that occur in the grid.
            </font>
            <v-img
              class="mx-auto"
              src="/impCompare.png"
              height="25%"
              width="25%"
            /> <br>
            <v-card-text
              class="text-center"
            >
              Examples of a Cost Function Visualization that track the accuracy of the IMP method.
            </v-card-text> <br> <br>
            <v-card-text
              class="text-left font-weight-bold pa-0"
            >
              Relevant Publications
            </v-card-text>
            <font
              size="3.5"
            >
              W. Trinh, K.S. Shetye, I. Idehen, and T. Overbye
              "terative Matrix Pencil Method for Power System Model Analysis,"
              <font
                class="font-italic"
              >
                2019 52nd Hawaii International Conference on System Sciences
              </font>
              January 2019
            </font> <br> <br>
            <font
              size="3.5"
            >
              W. Trinh, and T. Overbye,
              "Comparison of Dynamic Mode Decomposition and Iterative Matrix Pencil Method for Power System Modal Analysis,"
              <font
                class="font-italic"
              >
                2019 International Conference on Smart Grid Synchronized Measurements and Analytics,
              </font>
              May 2019
            </font> <br> <br>
            <font
              size="3.5"
            >
              W. Trinh and T.J. Overbye, "Sensitivity of Modes from Modal Analysis of Electric Grids,"
              <font
                class="font-italic"
              >
                Kansas Power and Energy Conference (KPEC),
              </font>
              April 2021
            </font>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Research',
  }
</script>
